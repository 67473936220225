import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { useState } from 'react';
import { Row, Col, Container, Card, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { ExclamationSquareFill } from 'react-bootstrap-icons';

export default function Dynamic_page() {
 
  const [isLoading, setIsLoading]=useState(false);
  const [page_data, setPage_data]=useState();

  var url = document.URL
  var page_name=url.substr(url.lastIndexOf('/') + 1);
  console.log(page_name)
  const location = useLocation();

  useEffect(()=>{
    axios.get('https://admin.bstlglobal.com/api/v1/dynamic_pages/'+page_name)
    .then(function (response) {
      setPage_data(response.data);
    })
  },[location]);

console.log(page_data)
  
let metaTitle, metaDescription, keywords;
if(page_name==='food'){
  metaTitle="Food";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Aquaponic,Salad leaves,baby leaves,baby bok choy,baby chard,baby kale,baby spinach,fish,asian seabass,tilapia,leafy greens,butterhead lettuce,curly kale leaf,green lollo,red lollo,spinach,swiss chard,toscana kale,vegetables,beans,cherry tomatoes,cucumber,green zucchini,roma tomatoes,vine tomatoes";
}else if(page_name=="health")
{
  metaTitle="Healthcare";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Healthcare,Medicine,Hospital,Pharmacy,cure,surgeon,diabetic care,surgery,obstetrics,gynaecology,cardiology,ENT,spine,Edoscopic,pediatrics,pathology,ophthalmology,endocrinology,psychology,dermatology,hepatobillary,physiotherapy,dental care,orthopedics,sports,medicine,emergency,radiology,urology";

}else if(page_name=="financial")
{
  metaTitle="Financial Services";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="finacialservice";
}else if(page_name=="energy")
{
  metaTitle="Financial Services";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Energy,Solar Energy,Renewable Energy,Solar Panel,power,solar,solar cell,bank of batteries,panel grid,solar grid,energy conversion,watts";
}else if(page_name=="asset")
{
  metaTitle="Asset Security & Asset Management";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Asset Security,Asset Management,Security,Camera";
}else if(page_name=="packaging")
{
  metaTitle="Packaging & Logistic Solutions";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Logistic";
}else if(page_name=="realestate")
{
  metaTitle="Real Estate and Construction Projects";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="Techno Elevators,Parking Solution,tower parking,basement cart parking,puzzle parking,stacker parking";
}else if(page_name=="fashion")
{
  metaTitle="Retail and Fashion";
  metaDescription="Stay informed about the latest trends and developments in healthcare, aquaponics, retail, renewable energy, packaging & logistics, fashion, real estate, construction, elevators, parking solutions, and security services through our comprehensive website.";
  keywords="ash amaira,shoes,hgandbags,purse,accessories,bridal,scarves";
}else{
  metaTitle="Bstlglobal";
  metaDescription="Bstlglobal";
  keywords="Bstlglobal";

}


if(isLoading){

  return (
    <>
    
    </>
  )

}else{

  return (

    <>
    <Helmet>
    <title>{metaTitle}</title>
    <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
    </Helmet>
    
    { page_data&&
      <>
        <Carousel className='inside-crousel'  indicators={false} controls={true} 
        autoPlay={true}
        interval={1000}
    
        >
            {
              JSON.parse(page_data.images).map((element, index) => {
                return (
                  <Carousel.Item  key={index} >
                    <img src={"https://admin.bstlglobal.com/"+element}
                      className="d-block"
                      width={'100%'}
                      height={'100%'}


                    />
                  </Carousel.Item>
                )
              })
            }
        </Carousel>

        <Container fluid className='inside-container'>
          <Row>

            <Col lg={8} md={7} xs={12} sm={12} style={{ paddingRight:'60px', display: 'flex', justifyContent: 'center' }} className="inside-col">
              <div className='inside-page-content '>
                <h4>{page_data.title} </h4>
                {Parser(page_data.content)}
              </div>
            </Col>
            <Col lg={4} md={5} sm={12}  className=" inside-page-col-link">
             <div className='inside-page-link-main' >
              {page_name=='energy'&&
                <div className='inside-page-links'>
        <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>

        <p><Link to="/page/green">Green Tech Energy & Water LLC</Link></p>
{/*         <p><Link  to="/page/bahwan">Bahwan RenewableEnergy Company  LLC</Link></p>
 */}       
       {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

                {page_name=='ABB_LLC' &&
                <div  className='inside-page-links'>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>

        <p><Link to="/page/green">Green Tech Energy & Water LLC  </Link></p>
        {/* <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p> */}

    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

{page_name=='hitachi' &&
                <div  className='inside-page-links'>
              <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        {/* <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p> */}
        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>

        <p><Link to="/page/green">Green Tech Energy & Water LLC  </Link></p>
        {/* <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p> */}

    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}
                </div>
                }

              

              {page_name=='green'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>       
         <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
        {/* <p><Link to="/page/green">Green Tech Energy & Watter LLC  </Link></p> */}
        {/* <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p> */}

    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }

              {page_name=='bahwan'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>

        <p><Link to="/page/green">Green Tech Energy & Water LLC  </Link></p>
        {/* <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p> */}

    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                   {page_name=='hyunet'&&
                <div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        {/* <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p> */}

        <p><Link to="/page/green">Green Tech Energy & Water LLC  </Link></p>
        {/* <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p> */}

    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                   {page_name=='thunder'&&
                < div  className='inside-page-links'>
             <p><Link to="/page/ABB_LLC">  ABB LLC </Link></p>
        <p><Link to="/page/hitachi">Hitachi Energy LLC</Link></p>
        <p><Link to="/page/green">Green Tech Energy & Water LLC  </Link></p>
        <p><Link  to="/page/bahwan">Bahwan Renewable Energy Company  LLC  </Link></p>

        <p><Link to="/page/hyunet">Hyunet Private Ltd </Link></p>
    {/*        <p><Link to="/page/thunder">ThunderVolt Technologies Pvt. Ltd</Link></p>
*/}                </div>
                }
                {
                  page_name=='food'&&
                  <div  className='inside-page-links'>

                      <p><Link to="/page/homeland">Homeland Plants LLC</Link></p>
                      <p><Link to="/page/blueaqua">Blue Aqua LLC</Link></p>
                      <p><Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link></p>

                  </div>
                }
                 {
                  page_name=='blueaqua'&&
                  <div  className='inside-page-links'>

                      <p><Link to="/page/homeland">Homeland Plants LLC</Link></p>
                      <p><Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link></p>

                  </div>
                }
                 {
                  page_name=='blueaquasinapure'&&
                  <div  className='inside-page-links'>

                      <p><Link to="/page/homeland">Homeland Plants LLC</Link></p>
                      <p><Link to="/page/blueaqua">Blue Aqua LLC</Link></p>
                  </div>
                }
                 {
                  page_name=='homeland'&&
                  <div  className='inside-page-links'>

                      <p><Link to="/page/blueaqua">Blue Aqua LLC</Link></p>
                      <p><Link to="/page/blueaquasinapure">Blue Aqua, Singapore</Link></p>

                  </div>
                }


                {
                  page_name=="health"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    {/* <p><Link to="/page/care">Care Plus LLC</Link></p> */}

                    </div>
                  
                }
                 {
                  page_name=="apollo"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    {/* <p><Link to="/page/care">Care Plus LLC</Link></p> */}

                    </div>
                  
                }
                 {
                  page_name=="international"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    {/* <p><Link to="/page/care">Care Plus LLC</Link></p> */}

                    </div>
                  
                }
                 {
                  page_name=="super"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    {/* <p><Link to="/page/care">Care Plus LLC</Link></p> */}

                    </div>
                  
                }
                 {
                  page_name=="care"&&
                  <div  className='inside-page-links'>
                    <p><Link to="/page/apollo">Apollo Hospital Muscat LLC</Link></p>
                    <p><Link to="/page/international">International Medical Center LLC</Link></p>
                    <p><Link to="/page/super">Apollo Super Speciality Hospital LLC</Link></p>
                    {/* <p><Link to="/page/care">Care Plus LLC</Link></p> */}
                    </div>
                  
                }
                {
                  page_name=='asset'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   {/* <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p> */}
                    </div>
                }
                
                {
                  page_name=='general'&&
                  <div  className='inside-page-links'>
                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>
                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>
                   <p><Link to="/page/lamnalco">      Bahwan  Lamnalco SAOC</Link></p>
                   {/* <p><Link to="/page/rosenbauer" >   Rosenbauer </Link> </p>
                   <p> <Link  to="/page/cantiere">    Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>
                   <p> <Link to="/page/loconav">      Loconav Inc</Link></p> */}
                    </div>
                }
                 {
                  page_name=='gulf'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   {/* <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p> */}
                    </div>
                }
                 {
                  page_name=='lamnalco'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/general"> General Dynamics Mission Systems-Gulf LLC</Link></p>

                   <p> <Link to="/page/gulf"> Gulf Security Services LLC</Link></p>

                   <p><Link to="/page/lamnalco">     Bahwan  Lamnalco SAOC</Link></p>

                   {/* <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p> */}
                    </div>
                }
                 
                 
                {
                  page_name=='internatinol'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>

                }

          {
                  page_name=='rosenbauer'&&
                  <div  className='inside-page-links'>


                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>

                }
                 {
                  page_name=='vanderlande'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>

                }
                {
                  page_name=='cantiere'&&
                  <div  className='inside-page-links'>

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                   <p> <Link to="/page/loconav">  Loconav Inc</Link></p>
                    </div>

                }
                
               





                 {
                  page_name=='loconav'&&
                  <div  className='inside-page-links'>

                  

                   <p><Link to="/page/rosenbauer" >  Rosenbauer </Link> </p>

                   <p> <Link  to="/page/cantiere">  Cantiere Navale Vittoria</Link></p>
                   <p><Link to="/page/vanderlande">   Vanderlande Industries</Link></p>

                    </div>
                }
                {
                  page_name=="packaging"&&
                  <div  className='inside-page-links'>
                     <p><Link to="/page/woodenm"> Al Qantar International SPC</Link></p>
                     {/* <p><Link to="/page/woodenr">PalletBiz</Link></p> */}
                    

                    </div>
                }
                
                 {
                  page_name=="woodenm"&&
                  <div  className='inside-page-links'>
                    
                    <img src='/Asets/al-image/4515191.jpeg' width={'100%'}  style={{paddingLeft:"10px"}}/>

                    </div>
                }
                {
                  page_name=="realestate"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="ejada"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="techno"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="intelligent"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                {
                  page_name=="ipark"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                
                {
                  page_name=="north"&&
                  <div  className='inside-page-links'>
                      {/* <p><Link to="/page/ejada">Ejada International Projects LLC</Link></p> */}
                      <p><Link to="/page/techno">Techno Elevators LLC</Link></p>
                      <p><Link to="/page/intelligent">Intelligent Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/ipark">IPark Robotic Parking & Elevators LLC</Link></p>
                      <p><Link to="/page/north">North Ocean Industries SPC</Link></p>
                     



                    </div>
                }
                
                
                {
                  page_name=="fashion"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>

                    <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
                    </div>
                }
                  {
                  page_name=="retail"&&
                  <div  className='inside-page-links'>

                    {/* <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p> */}

                    <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p>
                    </div>
                }
                  {
                  page_name=="accessories"&&
                  <div  className='inside-page-links'>

                    <p><Link to="/page/retail">Ash Amaira Retail Private Limited</Link></p>

                    {/* <p><Link to="/page/accessories" >Ash Amaira Accessories Trading LLC</Link></p> */}
                    </div>
                }
                {
                  page_name=="financial"&&
                  <div  className='inside-page-links'>
                    <p><Link to="/page/sadad">Sadad International LLC</Link></p>
                    </div>
                }
                 {
                  page_name=="bstlprofile"&&
                  <div  className='inside-page-links'>
                    <p><Link to="/management">Management</Link></p>
                    <p><Link to="/vision">Vision , Mission , Value </Link></p>
                    </div>
                }

            
                </div> 
            </Col>

           
          </Row>
        </Container>
      </>
   
          }
  
    </>
  )
}
}
